import "../../shared/global";
import "./index.css";
import { youtubeInit } from "../../shared/video/youtube";

import { tns } from "tiny-slider/src/tiny-slider";

const blockVideoEl = document.querySelector(".hero-video");
const RESIZE_DELAY = 200;
const LG_VIEWPORT = 981;
let oldWindowWidth = document.querySelector("html").clientWidth;
let timeout = false;

if (document.querySelector(".testimonials") !== null) {
	new tns({
	    container: '#testimonial-slider',
	    slideBy: 'page',
	    autoplay: true,
	    autoHeight: true,
	    nav: false,
	    controls: false,
	    autoplayButtonOutput: false,
	    speed: 1000
	});
}

youtubeInit();

window.addEventListener("load", function() {
    const windowWidth = document.querySelector("html").clientWidth;

    if (windowWidth >= 981) {
        implementVideoTag();
    }
});

window.onresize = () => {
    const videoEl = document.querySelector(".hero-video video");
    const newWindowWidth = document.querySelector("html").clientWidth;

    if (newWindowWidth !== oldWindowWidth) {
        if (timeout !== false) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            if (newWindowWidth >= LG_VIEWPORT && oldWindowWidth < LG_VIEWPORT) {
                console.log('add video');
                implementVideoTag();
            }
            if (newWindowWidth < LG_VIEWPORT && oldWindowWidth >= LG_VIEWPORT) {
                console.log('remove video');
                if (videoEl !== null) {
                    destroyVideoTag();
                }
            }

            oldWindowWidth = newWindowWidth;
        }, RESIZE_DELAY);
    }
};

const implementVideoTag = () => {
    blockVideoEl.innerHTML = "<video autoplay muted loop id=\"homeVideo\" poster=\"/dist/assets/images/hero-video-backup.jpg\"><source src=\"/dist/assets/videos/website.mp4\" type=\"video/mp4\"></video>";
};

const destroyVideoTag = () => {
    blockVideoEl.innerHTML = "";
};
