import "./global.css";

import LazyLoad from "vanilla-lazyload";

new LazyLoad({
    elements_selector: ".lazyload"
});

const menuToggleEl = document.querySelector(".header .menu-toggle");
const headerMenuEl = document.querySelector(".header-nav");

if (menuToggleEl !== null) {
	menuToggleEl.addEventListener("click", function (event) {
	    event.preventDefault();
	
	    headerMenuEl.classList.toggle("is-open");
	});
}

const mapPopUpLinkEls = Array.from(document.querySelectorAll("a[href^='#mapPopUp']"));
const mapEl = document.querySelector(".location-map");
const body = document.getElementsByTagName("body")[0];
let path = "";

if (mapEl) {
    mapPopUpLinkEls.forEach((link) => {
       link.addEventListener("click", (event) => {
           event.preventDefault();

           const href = event.currentTarget.getAttribute("href");
           path = "/" + href.split("?path=")[1];
           mapEl.classList.add("is-open");
           body.classList.add("open-map");
       });
    });


    const locationsEls = Array.from(document.querySelectorAll(".map-svg path"));

    locationsEls.forEach((locationEl) => {
        locationEl.addEventListener("click", (event) => {
           if (event.currentTarget.style.cursor === "pointer") {
                const subdomain = event.currentTarget.getAttribute("data-location");
                const domainObj = window.location.hostname.split(".");
                let domain = window.location.hostname;

               if (/^(!bluezoo)/i.test(domain)) {
                    domainObj.shift();
                    domain = domainObj.join(".");
               }

               window.location.href = `http://${subdomain}.${domain}${path}`;
           }
        });
    });

    const closeMapEl = document.querySelector(".map-close");

    closeMapEl.addEventListener("click", (event) => {
        event.preventDefault();

        mapEl.classList.remove("is-open");
        body.classList.remove("open-map");
    });
}
